import { inject, Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  router = inject(Router);

  constructor(private keycloakService: KeycloakService) {}

  login(): Promise<void> {
    return this.keycloakService.login();
  }

  async logout(): Promise<void> {
    await this.keycloakService.logout(
      window.location.origin + '/login', // Stelle sicher, dass wir zur Basis-URL zurückkehren
    );
    return this.keycloakService.clearToken();
  }

  async isLoggedIn(): Promise<boolean> {
    return this.keycloakService.isLoggedIn() && !this.keycloakService.isTokenExpired();
  }

  getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  // Optional: Manuelles Aktualisieren des Tokens
  refreshToken(): Promise<boolean> {
    return this.keycloakService.updateToken(30); // Aktualisiert, wenn das Token in weniger als 30 Sekunden abläuft
  }
}
